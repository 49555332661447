import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'vuetify/styles'; // Global CSS has to be imported
import { createVuetify } from 'vuetify';
import { loadFonts } from './plugins/webfontloader';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { createMetaManager } from 'vue-meta';

loadFonts();

const vuetify = createVuetify({
  components,
  directives
});

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(createMetaManager())
  .mount('#app');
