import bgldData from '@/../public/json_data.json';
import walsFeatures from '@/../public/features.json';
import * as _ from 'lodash';

export interface wals {
  location: loc;
  data: Array<walsData>;
}

export interface mapData {
  data: Array<wals>;
  features: Array<feature>;
}

export interface circle {
  radius: number;
  color: string;
  weight: number;
  fillOpacity: number;
}

export interface loc {
  lat: number;
  lon: number;
  name: string;
  id: string;
  chapter_id: Array<number>;
}

export interface walsData {
  lang_id: string;
  chapter: number;
  value: number | string;
  code_id: string;
  value_desc: string;
  source: string;
  parameter: string;
}

export interface codes {
  id: number;
  name: string;
  desc: string;
}

export interface feature {
  id: string;
  name: string;
  codes: Array<codes>;
  chapter: number;
}

export const parColors = [
  '#f00',
  '#0f0',
  '#00f',
  '#ff00ff',
  '#ccff00',
  '#daa520',
  '#ffa500'
];

export const jsonStore: mapData = {} as mapData;

function init() {
  const features = [] as Array<feature>;
  jsonStore.data = [];
  bgldData.forEach((el) => {
    const loc = el.Location;
    const data = [] as Array<walsData>;
    el.Data.forEach((el) => {
      if (el.Language_ID === 'burg1244') {
        // eslint-disable-next-line arrow-parens
        const feat = walsFeatures.find((e) => e.ID === el.Parameter_ID);
        features.push({
          id: el.Parameter_ID,
          name: el.Parameter_Name,
          chapter: el.Chapter_ID,
          codes: feat ? (feat.Codes as unknown as Array<codes>) : ([] as Array<codes>)
        });
      }
      data.push({
        lang_id: el.Language_ID,
        chapter: el.Chapter_ID,
        value: el.Value,
        code_id: el.Code_ID,
        value_desc: el.Value_Description,
        source: el.Source,
        parameter: el.Parameter_Name
      });
    });
    jsonStore.data.push({
      location: loc,
      data: data
    } as wals);
  });
  jsonStore.features = features;
}

export const tileSets = [
  {
    name: 'Humanitarian Open Tiles',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png '
  },
  {
    name: 'Wikimedia',
    url: 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png?lang=local'
  },
  {
    name: 'Minimal Ländergrenzen (hell)',
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',
    attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ'
  },
  {
    name: 'Minimal Ländergrenzen (dunkel)',
    url: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png?lang=de',
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
    subdomains: 'abcd'
  }
];

export const initialize = _.once(init);
