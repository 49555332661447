import { createStore } from 'vuex';

import {
  loc,
  circle,
  parColors,
  walsData,
  jsonStore,
  codes
}
  from './data';
import { generateShades } from '@/service/colorHelper';

export interface colorCode {
  chapter_id: number;
  feat_name: string;
  codes: Array<codes>;
  colors: Array<string>;
}

export interface featCol {
  name: string;
  id: number;
  colors: Array<string>;
}

export default createStore({
  state: {
    selectedLoc: [] as Array<loc>,
    displayDrawer: false,
    colorCode: [] as Array<colorCode>,
    circleSettings: {
      radius: 25,
      color: parColors[0],
      weight: 1,
      fillOpacity: 70
    } as circle
  },
  getters: {
    getLocations(state) {
      return state.selectedLoc;
    },
    getDisplayDrawer(state) {
      return state.displayDrawer;
    },
    getCircleSettings(state) {
      return state.circleSettings;
    },
    getColorCodes(state) {
      return state.colorCode;
    }
  },
  mutations: {
    setLocations(state, locations: Array<loc>) {
      state.selectedLoc = locations;
    },
    setDisplayDrawer(state, value: boolean) {
      state.displayDrawer = value;
    },
    setCircleSettings(state, val: circle) {
      state.circleSettings = val;
    },
    setColorCode(state, val: Array<colorCode>) {
      state.colorCode = val;
    },
    pushColorCode(state, val: colorCode) {
      state.colorCode.push(val);
    },
    removeColorCode(state, val: number) {
      state.colorCode.splice(state.colorCode.findIndex(el => el.chapter_id === val), 1);
    },
    removeChapterFromLocation(state, val: number) {
      state.selectedLoc.forEach((el) => {
        const idx = el.chapter_id.findIndex(el => el === val);
        if (idx > -1) {
          el.chapter_id.splice(idx, 1);
        }
      });
    }
  },
  actions: {
    generateColorShade(state, chapter: number) {
      const feature = jsonStore.features.find(el => el.chapter === chapter);
      let colors = [];
      if (feature) {
        colors = generateShades(
          feature.codes.length
        );
        state.commit('pushColorCode', {
          chapter_id: chapter,
          feat_name: feature.name,
          colors: colors,
          codes: feature.codes
        });
      }
    }
  },
  modules: {}
});
