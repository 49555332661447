/* eslint-disable indent */
import chroma from 'chroma-js';

let colorid = 0;

export const convertHslToStr = (a: number, s: number, l: number) =>
  `hsl(${a},${s * 100}%,${l * 100}%)`;

export const convertHexToHsl = (str: string) => {
  let r = parseInt(str.substring(1, 3), 16);
  let g = parseInt(str.substring(3, 5), 16);
  let b = parseInt(str.substring(5), 16);

  (r /= 255), (g /= 255), (b /= 255);
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h: number = (max + min) / 2;
  let s: number = h;
  const l: number = h;

  if (max == min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [h, s, l];
};

export const selectColor = (num: number | null) => {
  let angle = 0;
  if (num !== null) {
    angle = num * 137.508; // use golden angle approximation
  } else {
    angle = colorid * 137.508;
    angle -= Math.floor(angle / 360) * 360;
    colorid++;
  }
  // return `hsl(${angle},100%,75%)`;
  return {
    h: angle,
    s: 1,
    l: 0.75,
    a: 1
  };
};

// Used to generate a color and matching shades based on the needed amount
export const generateShades = (num: number) => {
  const colorStart = selectColor(null);
  const colorStop = selectColor(null);
  const chromaColorStart = chroma(
    colorStart.h,
    colorStart.s,
    colorStart.l,
    'hsl'
  ).hex();
  const chromaColorEnd = chroma(
    colorStop.h,
    colorStop.s,
    colorStop.l,
    'hsl'
  ).hex();
  return chroma
    .scale([chromaColorStart, chromaColorEnd])
    .mode('lab')
    .colors(num);
};
